import { Pipe, PipeTransform } from '@angular/core';

import { BaseCustomer } from './base.customer';

@Pipe({
  name: 'customerSelectFilter',
  standalone: true,
})
export class CustomerSelectFilterPipe implements PipeTransform {
  transform(customers: BaseCustomer[], search: string): BaseCustomer[] {
    if (!customers) {
      return [];
    }
    return customers.filter(
      (customer) =>
        customer.name.toLowerCase().search(search.toLowerCase()) !== -1,
    );
  }
}
